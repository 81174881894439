import { FormikButton } from "alat-components-library";
import { ReactComponent as NoTransactions } from "../../assets/svgs/transactions/no-transactions-illustration.svg";
import { DateInput } from "components/Inputs";
import PageLoader from "components/Loader/PageLoader";
import { ReactComponent as CaretIcon } from "assets/icons/next-page-icon.svg";
import { FiDownloadCloud } from "react-icons/fi";
import { ReactComponent as RefereeIcon } from "../../assets/svgs/referee-home-icon.svg";
import { Form, Formik } from "formik";
import { StateDropdown } from "components/Dashboard/components/StateDropdown";
import { useTransactionHistoryController } from "controllers/Payments/TransactionHistoryController";
import { TransactionOperation, currencies } from "utils/enums";
import { SelectDrop } from "components/Dashboard/components/SelectDrop";
import { formatNumberToCurrency, getOption } from "utils/helperFunctions";
import { useAppSelector } from "hooks/useReduxHook";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const TransactionHistory = () => {
  const navigate = useNavigate();
  const {
    pageStart,
    maxPageItems,
    totalTransactionCount,
    currentPage,
    maxPage,
    goToNextPage,
    goToPrevPage,
    accounts,
    userAccount,
    currency,
    accountNumber,
    from,
    to,
    setTransactionType,
    contentRef,
    loading,
    filteredTransactions,
    pageSize,
    debouncedPageInputChange,
    statusStyle,
    transactionStatus,
    handleReceiptDownload,
    handleSubmitFilterForm,
    handlePageSizeChange,
  } = useTransactionHistoryController();
  const authState = useAuthState();
  const userAccounts = useAppSelector((state) => state.accounts);
  const [selectedCurrency, setSelectedCurrency] = useState<string>(currency);
  // console.log("data", filteredTransactions);

  // console.log("selectedCurrency", selectedCurrency);

  return (
    <>
      {(loading?.includes("GET_TRANSACTION_HISTORY") ||
        loading?.includes("FILTER_TRANSACTIONS")) && <PageLoader message="" />}
      {/* Filter */}
      <div className="w-full h-auto shadow-lg py-2 px-4 rounded-lg bg-white">
        <p className="text-center font-semibold text-lg mb-5">
          Filter Transaction
        </p>
        <Formik
          initialValues={{
            accountNumber: userAccount || accountNumber,
            type: "",
            from: from,
            to: to,
          }}
          onSubmit={async (values) => {
            const payload = {
              ...values,
              currencyCode: selectedCurrency,
            };
            handleSubmitFilterForm(payload);
          }}
        >
          {({ handleChange, values, setFieldValue }) => (
            <Form>
              <div className="flex flex-col gap-6 py-3">
                <div className="grid grid-cols-12 gap-3">
                  <div className="w-full col-span-12 md:col-span-4">
                    <SelectDrop
                      variant="h-12 w-full text-sm"
                      name="accountNumberDebit"
                      label="Statement Account"
                      placeholder="--Select--"
                      value={values.accountNumber}
                      options={getOption(
                        "debit",
                        userAccounts?.allAccounts || authState.user.accounts
                      )}
                      onChange={(name: string, value: any) => {
                        setFieldValue(
                          "accountNumber",
                          JSON.parse(value.raw).accountNo
                        );
                        setSelectedCurrency(JSON.parse(value.raw).currency);
                      }}
                    />
                  </div>
                  <div className="w-full col-span-12 md:col-span-3">
                    <StateDropdown
                      variant="h-12 w-full text-sm"
                      name="type"
                      label="Select Type"
                      onChange={handleChange}
                      options={[
                        { name: "All", id: TransactionOperation.All },
                        { name: "Credit", id: TransactionOperation.Credit },
                        { name: "Debit", id: TransactionOperation.Debit },
                      ]}
                      onSelectOption={(e) => {
                        setTransactionType(e.id);
                      }}
                    />
                  </div>
                  <div className="w-full col-span-6 md:col-span-2">
                    <DateInput
                      name="from"
                      label="Start Date"
                      variant="border text-black w-full h-12 text-lg px-5"
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="w-full col-span-6 md:col-span-2">
                    <DateInput
                      name="to"
                      label="End Date"
                      variant="border text-black w-full h-12 text-lg px-5"
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3">
                  <div className="col-span-4 h-12 md:block hidden"></div>

                  <FormikButton
                    containerVariant="col-span-12 md:col-span-7 w-full h-12 rounded-md flex justify-center"
                    labelText="Display Records"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Content */}
      <div className="w-full relative">
        <div
          className="w-full pb-5 bg-white rounded-lg shadow-lg my-4"
          ref={contentRef}
        >
          <div className="flex justify-center items-center">
            <div className="font-semibold py-2">Transactions</div>
          </div>
          <hr />
          <div className="overflow-x-auto">
            <table className="table-auto min-w-max md:w-full overflow-x-auto text-xs font-semibold text-left py-2 p-1">
              <thead>
                <tr>
                  <th className="py-2 px-4 pl-4 bg-white md:sticky md:left-0 md:top-0 md:z-[2]">
                    Description
                  </th>
                  <th className="py-2 px-4">Date</th>
                  <th className="py-2 px-4">{`Amount (${selectedCurrency})`}</th>
                  <th className="py-2 px-4">Status</th>
                  <th className="py-2"></th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions?.data?.map(
                  (transaction: any, index: any) => {
                    const date = new Date(transaction.date);
                    const options = {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    } as Intl.DateTimeFormatOptions;
                    const formattedDate = date.toLocaleDateString(
                      "en-US",
                      options
                    );
                    // const isTransactionBackedUp =
                    //   transaction?.creditType?.toLowerCase() === "debit" &&
                    //   transaction?.status?.toLowerCase() === "successfull" &&
                    //   !transaction?.isViewReceiptEnabled &&
                    //   transaction?.narration?.toLowerCase() !==
                    //     "comm alat nip transfer" &&
                    //   transaction?.narration?.toLowerCase() !==
                    //     "vat alat nip transfer";
                    return (
                      <tr
                        className="text-[#333333] odd:bg-gray-100 even:bg-white"
                        key={index}
                      >
                        <td
                          className={`py-3 px-4 text-left pl-4 flex items-center ${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                        >
                          <RefereeIcon className="h-10 text-[#A90836] ml-2" />
                          {transaction?.narration}
                        </td>
                        <td className="py-3 px-4 break-all">{formattedDate}</td>
                        <td
                          className={`py-3 px-4 break-all ${statusStyle(
                            transaction?.creditType
                          )}`}
                        >
                          {`${
                            transaction?.creditType?.toLowerCase() === "debit"
                              ? "-"
                              : "+"
                          }${formatNumberToCurrency({
                            number: transaction?.amount,
                            currencyCode: selectedCurrency,
                          })}`}
                        </td>
                        <td>
                          <p
                            className={`${transactionStatus(
                              transaction?.status
                            )} px-2 py-1 text-center`}
                          >
                            {transaction?.status?.toLowerCase() === "default"
                              ? ""
                              : transaction?.status}
                          </p>
                        </td>
                        {/* {isTransactionBackedUp && (
                          <td
                            className="px-4 font-semibold text-lib-alat-dark-red cursor-pointer"
                            onClick={() => {
                              navigate("/payments/transaction-receipt", {
                                state: {
                                  isBackedUp: true,
                                  payload: {
                                    transactionStan:
                                      transaction?.transactionStan,
                                    version: "1",
                                  },
                                },
                              });
                            }}
                          >
                            <div className="flex items-center gap-2">
                              <FiDownloadCloud />
                              <span>Download</span>
                            </div>
                          </td>
                        )} */}
                        {transaction?.creditType?.toLowerCase() === "debit" &&
                          transaction?.status?.toLowerCase() ===
                            "successfull" &&
                          transaction?.isViewReceiptEnabled && (
                            // !isTransactionBackedUp &&
                            <td
                              className="px-4 font-semibold text-lib-alat-dark-red cursor-pointer"
                              onClick={() => handleReceiptDownload(transaction)}
                            >
                              <div className="flex items-center gap-2">
                                <FiDownloadCloud />
                                <span>Download</span>
                              </div>
                            </td>
                          )}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
            {totalTransactionCount < 1 && (
              <div className="flex flex-col items-center">
                <NoTransactions className="mx-auto w-48 h-48" />
                <div className="font-bold text-sm -mt-10">No Transactions</div>
              </div>
            )}
          </div>
        </div>
        {/* Pagination */}
        <div className="flex w-full justify-between">
          <div className="flex gap-x-3 items-center">
            <div className="text-xs text-[#333333]">Rows per page</div>

            <select
              value={pageSize}
              className="text-xs text-[#333333] border border-[#333333] rounded-[6px] h-[36px] min-w-[56px] cursor-pointer bg-['transparent']"
              onChange={(e) => handlePageSizeChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="text-xs text-[#333333]">{`Showing ${pageStart} to ${maxPageItems} of ${totalTransactionCount} results`}</div>
          <div className="text-[#333333] flex gap-2 items-center">
            <CaretIcon
              className="rotate-180 cursor-pointer"
              onClick={goToPrevPage}
            />
            <input
              type="text"
              className="w-6 h-6 border-[#333333] outline-none border rounded-sm text-center"
              onChange={debouncedPageInputChange}
              // value={currentPage}
              placeholder={currentPage}
              max="10"
            />
            <div>out of</div>
            <div>{maxPage}</div>
            <CaretIcon
              className="text-lib-alat-dark-red cursor-pointer"
              onClick={goToNextPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
