// eslint-disable-next-line
// @ts-nocheck
import TwitterCLDR from "twitter_cldr/full/core";
import TwitterCLDRDataBundle from "twitter_cldr/full/en";
import { ToastNotify } from "../helpers/toastNotify";
import { currencyENUMs, accountStatementTypeEnum } from "./enums";
import { chargesList } from "./constant/chargesList";
import { TransactionTypeEnum } from "./enums/transaction";
import { countryCodes } from "../alat-components-library/utils/countryCode";

TwitterCLDR.set_data(TwitterCLDRDataBundle);

// export const copyContent = (text: string) => {
//   // try {
//   //   await navigator.clipboard.writeText(text);
//   //   return ToastNotify({
//   //     type: "success",
//   //     message: "Copied!",
//   //     position: "top-center",
//   //   });
//   // } catch (err) {
//   //   return ToastNotify({
//   //     type: "error",
//   //     message: `${err && "Failed to copy!"}`,
//   //     position: "top-center",
//   //   });
//   // }
// }

/**
 * Format number with desired currency
 *
 * @param {object} args The arguments for the function
 * @param {number | string} args.number The number to be converted
 * @param {string} args.currencyCode The currecncy code to use
 * @param {string} args.precision The number of decimal places
 * @returns {string} String number containing currency
 */
export const formatNumberToCurrency = ({
  number,
  currencyCode = 1,
  precision = 2,
}: any) => {
  const formatter = new TwitterCLDR.CurrencyFormatter();
  return formatter.format(Number(number), {
    currency: currencyENUMs[currencyCode],
    precision,
  });
};

export const extractNumberFromString = (str) => {
  const replaced = str.replace(/\D/g, ""); // 👉️ '123'

  let num;

  if (replaced !== "") {
    num = Number(replaced); // 👉️ 123
  }

  // console.log(num)
  return num;
};

type MaskString = {
  string: string;
};
/**
 *
 * @param {object} args The function arguments
 * @param {string} args.string The string to be masked
 * @returns {string} Masked String
 */
export const maskString = ({ string }: MaskString) => {
  if (typeof string !== "string")
    throw new Error("'String' must be of type string");
  const strArray = string.split("@");
  const stringToMask = strArray?.[0];
  const firstChar = stringToMask.slice(0, 1);
  const maskedChars = stringToMask.slice(2).replace(/./g, "*");
  const newString = firstChar + maskedChars + "@" + strArray?.[1];
  return newString;
};

export function compareArr(arr1: string[], arr2: string[]) {
  return arr1.sort().join(",") === arr2.sort().join(",");
}

export const generateCharge = (
  amount: number,
  type: string,
  charges: {
    id: number;
    charge: number;
    chargeFeeName: string;
    lower: number;
    transactionType: number;
    upper: number;
  }[],
  bankCode
) => {
  if (
    (type?.toLowerCase() === "others" || type?.toLowerCase() === "both") &&
    bankCode !== "035"
  ) {
    if (amount > charges?.[0]?.lower && amount <= charges?.[0]?.upper)
      return charges?.[0]?.charge;
    if (amount > charges?.[0]?.upper && amount <= charges?.[1]?.upper)
      return charges?.[1]?.charge;
    if (amount > charges?.[1]?.upper) return charges?.[2]?.charge;
  }
  return 0;
};

export const generateChargeTwo = (amount: number) => {
  if (amount > chargesList[0].lower && amount <= chargesList[0].upper)
    return chargesList[0].charge;
  if (amount > chargesList[0].upper && amount <= chargesList[1].upper)
    return chargesList[1].charge;
  if (amount > chargesList[1].upper) return chargesList[2].charge;
  return 0;
};

// export const Logger = (...logs) => (process.env.NODE_ENV === "development"
//   ? console.log(...logs, `(Log time - ${dayjs().format("LLL")})`)
//   : undefined);

export const getTransactionType = (
  selectedBank: {
    bankCode: string;
    bankName: string;
  },
  authState
) => {
  let transactionType = TransactionTypeEnum.SingleSignatoryInterTransfer; // Sole Proprietor

  if (
    ["initiator", "initiatorapprover"]?.includes(
      authState.userRole?.toLowerCase()
    )
  ) {
    transactionType = TransactionTypeEnum.MultipleSignatoryInterTransfer;
  }

  if (
    ["initiator", "initiatorapprover"]?.includes(
      authState.userRole?.toLowerCase()
    ) &&
    selectedBank.bankCode === "035"
  ) {
    transactionType = TransactionTypeEnum.MultipleSignatoryIntraTransfer;
  }

  if (
    ["soleproprietor"]?.includes(authState.userRole?.toLowerCase()) &&
    selectedBank.bankCode === "035"
  ) {
    transactionType = TransactionTypeEnum.SingleSignatoryIntraTransfer;
  }

  return transactionType;
};

export const moneyInputFormat = (value: string | number) => {
  // console.log("value", value)

  let amount = value.toString();
  if (amount === "" || amount === undefined || amount === "0.")
    return {
      withMoneyFormat: "0",
      withNumberFormat: 0,
    };

  // remove the , from the amount before formating
  const removeComma = amount.split(",").join("");
  // console.log("etest", removeComma)
  // console.log("Netest", Number(removeComma))
  // Haddle a regex test
  const reg = /^\d+\.?\d{0,2}?$/g;

  if (reg.test(removeComma)) {
    let formatedData = removeComma.replace(
      /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
      "$&,"
    );
    const checkIfDecimal = removeComma.split(".");
    // console.log("checkIfDecimal", checkIfDecimal)
    if (checkIfDecimal.length > 1 && checkIfDecimal[1] === "")
      formatedData = `${formatedData}`;

    if (formatedData.split("")[0] === "0") {
      return {
        withMoneyFormat: formatedData.slice(1, formatedData.length),
        withNumberFormat: Number(removeComma),
      };
    }

    return {
      withMoneyFormat: formatedData,
      withNumberFormat: Number(removeComma),
    };
  }
};

export const getCountryCode = (country: string) => {
  return countryCodes?.filter(
    (countryCode: { country: string }) =>
      countryCode.country.toLowerCase() === country.toLowerCase()
  )[0];
};
// handle download image file
export const downloadBlobFile = async (url?: string, fileName?: string) => {
  if (!url || !fileName) {
    return;
  }
  try {
    // Fetch the image data
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    // Convert the image data to a Blob
    const blob = await response.blob();
    // Create a Blob URL
    const blobUrl = URL.createObjectURL(blob);
    // Create a link element
    const downloadLink = document.createElement("a");
    // Set the link's href to the Blob URL
    downloadLink.href = blobUrl;
    // Set the download attribute with the desired file name
    downloadLink.download = fileName;
    // Append the link to the document
    document.body.appendChild(downloadLink);
    // Trigger a click on the link to start the download
    downloadLink.click();
    // Remove the link and revoke the Blob URL from the document
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error fetching or processing the image:", error);
  }
};

export const downloadCSVFile = (fileURL, fileName) => {
  const url = `data:application/csv;base64,${fileURL}`;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
export const downloadPDFFile = (fileURL, fileName) => {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_blank";
    var filename = fileURL?.substring(fileURL?.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
};

export const getAccountStatementTypes = () => {
  let accountOptions = [
    {
      label: "Account Statement",
      value: accountStatementTypeEnum.AccountStatement,
      id: accountStatementTypeEnum.AccountStatement,
    },
    {
      label: "Cleared Cheque Statement",
      value: accountStatementTypeEnum.ChequeClearance,
      id: accountStatementTypeEnum.ChequeClearance,
    },
  ];
  return accountOptions;
};

export const getAllAccounts = (accounts: any) => {
  let accountOptions = [];
  accounts?.map((item: any, index: number) => {
    accountOptions.push({
      label: `${item?.accountDescription} (${
        item?.accountNo
      }) - ${formatNumberToCurrency({
        number: item?.balance,
        currencyCode: item?.currency,
      })}`,
      value: item?.accountNo,
      id: item?.accountNo,
      raw: JSON.stringify(item),
    });
  });
  return accountOptions;
};

export const getOption = (type: string, accounts: any) => {
  // console.log("acct", accounts)
  let accountOptions = [];
  accounts?.map((account: any, index: number) => {
    const availableBalance = getCurrencyFormat(account);
    if (account?.currency?.toLowerCase() !== "ngn") return;

    return accountOptions.push({
      label: `
      ${account?.accountName} (${account.accountNo}) - 
      ${new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: availableBalance.currency,
      })
        .format(Number(account.balance))
        .replace(
          availableBalance.currency,
          availableBalance?.unicodeCharacter || availableBalance?.currency
        )
        .trim()}
      `,
      value: account.accountNo,
      id: account.accountNo,
      raw: JSON.stringify(account),
    });
  });
  return accountOptions;
};

export const getCurrencyFormat = (account: any) => {
  let availableBalance: any = {
    currency: "NGN",
    unicodeCharacter: "₦",
  };

  if (account?.currency?.toLowerCase() === "usd") {
    availableBalance = {
      currency: getCountryCode("united states")?.currency_code,
      unicodeCharacter: undefined,
    };
  }

  return availableBalance;
};

export const redirectTo = (path: string) => window.location.replace(path);

export const getAuthType = (
  amount,
  responseCode,
  authState,
  isMultipleSignatory?: boolean
) => {
  let authType: any;
  if (amount <= 2000000) authType = "pinOtp";
  if (amount > 2000000 && authState?.user?.hasSoftToken) authType = "softToken";
  if (amount > 2000000 && authState?.user?.hasSoftToken === false)
    authType = "hardTokenPin";
  if (amount <= 2000000 && responseCode === 21 && authState?.user?.hasSoftToken)
    authType = "softToken";
  if (
    amount <= 2000000 &&
    responseCode === 21 &&
    authState?.user?.hasSoftToken === false
  )
    authType = "softToken";
  if (
    amount <= 2000000 &&
    authState?.user?.hasSoftToken === false &&
    authState?.user?.hasTransactionPin === false
  )
    authType = "hardTokenPin";
  if (
    authState?.user?.hasSoftToken &&
    authState?.user?.hasTransactionPin === false
  )
    authType = "softToken";
  // For multiple signatory when "isMultipleSignatory" is true
  if (!authState?.user?.hasSoftToken && isMultipleSignatory)
    authType = "hardTokenPin";
  if (authState?.user?.hasSoftToken && isMultipleSignatory)
    authType = "softToken";
  return authType;
};

export const accountsToDebit = (accounts: Accounts[], currency: string) => {
  let acct = [];
  let accountsList = accounts?.filter(
    (item: any) =>
      item?.accountStatus?.toLowerCase() === "active" &&
      item?.currency === currency &&
      item?.isDebitable
  );
  for (let item of accountsList) {
    acct.push({
      id: item?.accountId,
      label: `${item?.accountDescription} (${
        item?.accountNo
      }) - ${formatNumberToCurrency({
        number: item?.balance,
        currencyCode: item?.currency,
      })}`,
      value: item?.accountNo,
    });
  }
  return acct;
};
const ErrorNotification = (err: string) =>
  ToastNotify({
    type: "error",
    message: err,
    position: "top-right",
  });

export const handleErrors = (error?: string | string[]) => {
  if (!error) {
    return ErrorNotification("Oops! Something went wrong");
  }
  const isArray = Array.isArray(error);
  if (isArray) {
    return error.flat().map((err: string) => ErrorNotification(err));
  } else {
    return ErrorNotification(error);
  }
};
