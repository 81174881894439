import { ResponseMessage } from "./../../react-wrappers/stores/redux/interfaces";
import { ToastNotify } from "helpers/toastNotify";
import { useBankLists } from "hooks/apis/Payment/useBankLists";
import { useNIPCharges } from "hooks/apis/Payment/useNIPCharges";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-wrappers/stores/redux/auth/slices";
import {
  GET_TRANSACTION_STATUS_ACTION,
  POST_TRANSACTION_VALIDATE_ACTION,
  PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION,
} from "react-wrappers/stores/redux/payments/actions";
import {
  setResponseCode,
  setTransferResponseMessage,
  usePaymentState,
} from "react-wrappers/stores/redux/payments/slices";
import {
  TransactionResponseStatusEnum,
  TransactionTypeEnum,
} from "utils/enums";
import {
  generateChargeTwo,
  getTransactionType,
  moneyInputFormat,
} from "utils/helperFunctions";

const useNewTransfer = () => {
  const { bankLists, handleGetBankList } = useBankLists();
  const { nipCharges, handleGetNIPCharges } = useNIPCharges();

  const authState = useAuthState();
  const paymentState = usePaymentState();
  const dispatch = useAppDispatch();
  const userAccounts = useAppSelector((state) => state.accounts);
  const allAccounts =
    userAccounts?.allAccounts?.length > 0
      ? userAccounts.allAccounts
      : authState?.user?.accounts;
  // const location = useLocation();
  const navigate = useNavigate();

  const [accountNumber, setAccountNumber] = useState<string>("");
  const [selectedBank, setSelectedBank] = useState({
    bankCode: "",
    bankName: "",
  });
  const [amount, setAmount] = useState({
    withMoneyFormat: "0",
    withNumberFormat: 0,
  });
  // const [pin, setPin] = useState<string>("");
  // const [enableSubmit, setEnableSubmit] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [showScreen, setshowScreen] = useState(1);

  const [beneficiaryName, setBeneficiaryName] = useState<string>("");
  const [accountValidated, setAccountValidated] = useState<boolean>(false);
  const [accountValidationError, setAccountValidationError] =
    useState<string>("");
  const [correlationId, setCorrelationId] = useState<string>("");
  // const [accountToCredit, setAccountToCredit] = useState({});
  const [accountToDebit, setAccountToDebit] = useState({
    accountId: "",
    currency: "",
    balance: 0,
    accountName: "",
    accountNo: "",
  });
  const [description, setDescription] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [authToken, setAuthToken] = useState<string>("");
  const [authType, setAuthType] = useState("pinOtp");
  const [isPinValidated, setIsPinValidated] = useState<boolean>(false);
  const [showPinError, setShowPinError] = useState("no");
  const [pinError, setPinError] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [otpMessage, setOtpMessage] = useState<string>("");
  // const [responseCode, setResponseCode] = useState<number>();
  // const [accountValidated, setAccountValidated] = useState<boolean>(false);
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);

  useEffect(() => {
    // GetBankLists();
    handleGetBankList();
    handleGetNIPCharges();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event: {
    target: { name: any; value: any; dataset: any };
  }) => {
    // console.log(event.target.name, event.target.value);
    // const getAuthType = event?.target?.dataset.authType;
    // console.log(getAuthType)

    if (event.target.name === "AccountNumber") {
      const value = event.target.value.replace(/\D/g, "");
      setAccountNumber(value);
      setAccountValidated(false);
    }
    if (event.target.name === "selectedBank") {
      setSelectedBank(JSON.parse(event.target.value));
      setAccountNumber("");
      setAccountValidated(false);
    }

    if (event.target.name === "amount") {
      // console.log("rawValue", event.target.value);
      const result = moneyInputFormat(event.target.value);
      // console.log("result", result);
      result && setAmount(result);
    }
    if (event.target.name === "otp") {
      if (event.target.value.length > 6) {
        return;
      }
      const result = event.target.value.replace(/\D/g, "");

      setOtp(result);
      // if (result.length === 4) {
      //   handlePinValidation(result);
      // }
    }
    if (event.target.name === "description") {
      setDescription(event.target.value);
    }
    if (event.target.name === "pin") {
      setShowPinError("no");
      setAllowSubmit(false);
      setIsPinValidated(false);

      const result = event.target.value.replace(/\D/g, "");

      setPin(result);
      if (result.length === 4) {
        setIsPinValidated(true);
        setShowPinError("no");
        return setAllowSubmit(true);

        // if (getAuthType !== "hardTokenPin") {
        //   setIsPinValidated(true);
        //   setAllowSubmit(true);
        //   setShowPinError("no");
        //   return handlePinValidation({
        //     setIsPinValidated,
        //     setAllowSubmit,
        //     setShowPinError,
        //   });
        // }
        // setIsPinValidated(true);
        // setShowPinError("no");
        // return setAllowSubmit(true);
      }
    }
    if (event.target.name === "authToken") {
      const result = event.target.value.replace(/\D/g, "");
      setAllowSubmit(false);
      setAuthToken(result);
      if (result.length === 6) {
        // if (getAuthType !== "hardTokenPin")
        //   handlePinValidation({
        //     setIsPinValidated,
        //     setAllowSubmit,
        //     setShowPinError,
        //   });
        setIsPinValidated(true);
        setShowPinError("no");
        return setAllowSubmit(true);
      }
      // if (result.length === 4) {
      //   handlePinValidation(result);
      // }
    }
    // if (event.target.name === "pin") {
    //   setShowPinError("no");
    //   const result = event.target.value.replace(/\D/g, "");

    //   setPin(result);
    //   if (result.length === 4) {
    //     handlePinValidation(result);
    //   }
    // }
    if (event.target.name === "selectedAccount") {
      const convertedAccountDetails = JSON.parse(event.target.value);
      setAccountToDebit(convertedAccountDetails);

      // const filterAccounts = accounts.filter(
      //   (account) => account.accountNo !== convertedAccountDetails.accountNo
      // );
      // setShowAccounts(filterAccounts);
    }
    // setFundAccount({ ...fundAccount, [event.target.name]: event.target.value });
  };
  const handleBackToFundsTransfer = () => {
    navigate("/payments/funds-transfer");
  };
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();

    let transactionType = getTransactionType(selectedBank, authState);

    let transactionFee = 0;
    if (selectedBank?.bankCode !== "035") {
      transactionFee = generateChargeTwo(amount?.withNumberFormat);
    }

    const body = {
      sourceAccountNumber: accountToDebit?.accountNo,
      sourceAccountName: accountToDebit?.accountName,
      sourceAccountCurrencyCode: accountToDebit?.currency,
      transactionType: transactionType,
      transferDestinations: [
        {
          beneficiaryAccountNumber: accountNumber,
          beneficiaryName: beneficiaryName,
          beneficiaryBankName: selectedBank?.bankName,
          beneficiaryBankCode: selectedBank?.bankCode,
          amount: amount?.withNumberFormat,
          transactionFee: transactionFee,
          beneficiaryCurrency: accountToDebit?.currency,
          narration: description,
        },
      ],
      multipleTransferGroupName: "",
      // transactionPin: pin,
      createMultipleTransferGroup: false,
      transactionPin: pin,
      token: authToken,
      isSoftToken: authState?.user?.hasSoftToken,
    };

    // console.log(body, "body");

    try {
      setisLoading(true);
      dispatch(POST_TRANSACTION_VALIDATE_ACTION(body))
        .unwrap()
        .then((res: any) => {
          // console.log("res-trans-validate", res);
          setisLoading(false);
          if (res.status === 200) {
            setCorrelationId(res.data.data.correlationId);
            dispatch(
              setTransferResponseMessage({
                title: res?.data?.responseMessage?.title,
                actionMessage: res?.data?.responseMessage?.actionMessage,
              })
            );
            if (authState.userRole !== "initiator") {
              // console.log("no cap");

              setshowScreen(5);
            } else {
              // console.log("there is a success");
              setshowScreen(9);
            }
          } else {
            ToastNotify({
              type: "error",
              message:
                res?.status === 500
                  ? "Something went wrong"
                  : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            });
          }
        })
        .catch((e: any) => {
          // console.log("err", e);
          setisLoading(false);
          setshowScreen(7);
        });

      // setshowScreen(7);
    } catch (e) {
      // console.log("er", e);
      setisLoading(false);
      if (authState.userRole !== "initiator") {
        setshowScreen(7);
      } else {
        setshowScreen(10);
      }
    }
  };

  // Otp Submit Initiaotr
  const handleOtpSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    // console.log("display here")

    let transactionType = getTransactionType(selectedBank, authState);

    const payload = {
      transactionType: transactionType,
      code: otp,
      correlationId: correlationId,
    };

    dispatch(PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        // console.log("res-otp-submit", res);
        dispatch(
          setTransferResponseMessage({
            title: res?.data?.responseMessage?.title,
            actionMessage: res?.data?.responseMessage?.actionMessage,
          })
        );
        if (
          res?.data?.httpStatusCode === 200 &&
          authState.userRole !== "initiator"
        )
          setshowScreen(5);
        if (
          res?.data?.httpStatusCode === 200 &&
          authState.userRole === "initiator"
        )
          setshowScreen(9);
        if (res.status !== 200) {
          return ToastNotify({
            type: "error",
            message:
              res?.status >= 500
                ? "Oops! Something went wrong"
                : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}` ||
                  "Something went wrong",
          });
        }
      })
      .catch((e: any) => {
        // console.log(e);
        setshowScreen(7);
      });
  };
  //

  const handleScreenChangeNext = () => {
    if (showScreen === 2) {
      if (amount.withNumberFormat <= 2000000) {
        setAuthType("pinOtp");
      }
      if (amount.withNumberFormat > 2000000) {
        if (authState?.user?.hasSoftToken) {
          setAuthType("softToken");
        } else {
          setAuthType("hardTokenPin");
        }
      }
    }

    if (showScreen === 3) {
      if (authType !== "pinOtp") {
        setshowScreen(5);
      } else {
        setshowScreen(4);
      }
    } else {
      setshowScreen(showScreen + 1);
    }
  };
  const handleScreenChangePrev = () => {
    // console.log(showScreen);
    if (showScreen === 2) {
      setAccountNumber("");
      setAccountValidated(false);
      setAccountToDebit({
        accountId: "",
        currency: "",
        balance: 0,
        accountName: "",
        accountNo: "",
      });
      setAmount({
        withMoneyFormat: "0",
        withNumberFormat: 0,
      });
    }
    if (showScreen === 3) {
      // setAccountToDebit({
      //   accountId: "",
      //   currency: "",
      //   balance: 0,
      //   accountName: "",
      //   accountNo: "",
      // });
      // setAmount({
      //   withMoneyFormat: "0",
      //   withNumberFormat: 0
      // });
      setAllowSubmit(false);
    }
    setshowScreen(showScreen - 1);
  };
  const handleRefreshTrasaction = async () => {
    try {
      setisLoading(true);
      dispatch(GET_TRANSACTION_STATUS_ACTION(correlationId))
        .unwrap()
        .then((res: any) => {
          // console.log("new-status", res);
          setisLoading(false);
          // dispatch(
          //   setTransferResponseMessage({
          //     title: res?.data?.responseMessage?.title,
          //     actionMessage: res?.data?.responseMessage?.actionMessage,
          //   })
          // );
          if (res?.status === 200) {
            if (
              res?.data?.data?.transactions?.[0]?.status ===
                TransactionResponseStatusEnum.Pending ||
              res?.data?.data === null
            ) {
              ToastNotify({
                type: "error",
                message:
                  res?.data?.data === null
                    ? `${res?.data?.responseMessage?.title}. ${res?.data?.responseMessage?.actionMessage}`
                    : res?.data?.data?.transactions?.[0]?.statusMessage ?? "",
              });
            } else {
              dispatch(
                setTransferResponseMessage({
                  title: res?.data?.data?.transactions?.[0]?.statusMessage,
                  actionMessage: "",
                })
              );
              if (
                res.data.data.transactions[0].status ===
                TransactionResponseStatusEnum.Success
              ) {
                // setisLoading(false);
                setshowScreen(6);
              }
              if (
                res.data.data.transactions[0].status ===
                TransactionResponseStatusEnum.Failed
              ) {
                // setisLoading(false);
                setshowScreen(7);
              }
            }
          } else {
            ToastNotify({
              type: "error",
              message:
                res?.status >= 500
                  ? "Oops! Something went wrong"
                  : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}`,
            });
          }
        })
        .catch((e: any) => {
          setisLoading(false);
          setshowScreen(7);
        });
    } catch (e) {
      setisLoading(false);
      // console.log(e);
    }
  };
  const handleGenerateReceipt = () => {
    navigate("/payments/transaction-receipt", {
      state: { payload: { correlationId, version: "1" } },
    });
  };
  const handleTryAgain = () => {
    // setAccountNumber("");

    setshowScreen(3);
  };
  const handleOtpValidationSubmit = async (values: any) => {
    // console.log(values);
    let transactionType = TransactionTypeEnum.SingleSignatoryInterTransfer; // Sole Proprietor

    if (authState.userRole === "initiator") {
      transactionType = TransactionTypeEnum.MultipleSignatoryInterTransfer;
    }

    if (authState.userRole === "initiator" && selectedBank.bankCode === "035") {
      transactionType = TransactionTypeEnum.MultipleSignatoryIntraTransfer;
    }

    if (authState.userRole !== "initiator" && selectedBank.bankCode === "035") {
      transactionType = TransactionTypeEnum.SingleSignatoryIntraTransfer;
    }

    const payload = {
      transactionType,
      code: otp,
      correlationId: correlationId,
    };
    // console.log(payload);
    dispatch(PUT_COMPLETE_TRANSFER_TRANSACTION_ACTION(payload))
      .unwrap()
      .then((res: any) => {
        dispatch(
          setTransferResponseMessage({
            title: res?.data?.responseMessage?.title,
            actionMessage: res?.data?.responseMessage?.actionMessage,
          })
        );
        // console.log("res-otp-valid-submit", res);
        if (
          res?.data?.httpStatusCode === 200 &&
          authState.userRole !== "initiator"
        )
          setshowScreen(5);
        if (
          res?.data?.httpStatusCode === 200 &&
          authState.userRole === "initiator"
        )
          setshowScreen(9);

        if (res?.data?.data?.hasError) {
          return ToastNotify({
            type: "error",
            message:
              res?.status >= 500
                ? "Oops! Something went wrong"
                : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}` ||
                  "Something went wrong",
          });
        }
      })
      .catch((error: any) => {
        // console.log(error);
        // redirect to the error screen
        setshowScreen(7);
      });
  };
  const handleValidate = async (event: { preventDefault: () => void }) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();

    let transactionFee = 0;
    let transactionType = getTransactionType(selectedBank, authState);

    if (selectedBank.bankCode !== "035") {
      transactionFee = generateChargeTwo(amount?.withNumberFormat);
    }

    const body = {
      sourceAccountNumber: accountToDebit?.accountNo,
      sourceAccountName: accountToDebit?.accountName,
      sourceAccountCurrencyCode: accountToDebit?.currency,
      transactionType,
      transferDestinations: [
        {
          beneficiaryAccountNumber: accountNumber,
          beneficiaryName: beneficiaryName,
          beneficiaryBankName: selectedBank?.bankName,
          beneficiaryBankCode: selectedBank?.bankCode,
          amount: amount?.withNumberFormat,
          beneficiaryCurrency: accountToDebit?.currency,
          transactionFee,
          narration: description,
        },
      ],
      multipleTransferGroupName: "",
      createMultipleTransferGroup: false,
      transactionPin: pin,
      token: authToken,
      isSoftToken: authState?.user?.hasSoftToken,
    };

    try {
      setisLoading(true);
      dispatch(POST_TRANSACTION_VALIDATE_ACTION(body))
        .unwrap()
        .then((res: any) => {
          setisLoading(false);
          if (res?.data?.data?.responseCode === 21) {
            dispatch(setResponseCode(res?.data?.data?.responseCode));
          }
          if (res.status === 200) {
            setCorrelationId(res.data.data.correlationId);
            setOtpMessage(res?.data?.responseMessage?.actionMessage);
            if (
              res?.data?.data?.correlationId &&
              Number(amount.withNumberFormat) < 2000000
            ) {
              setshowScreen(4);
            }
            if (res?.data?.data?.responseCode === 21) {
              dispatch(setResponseCode(res?.data?.data?.responseCode));
            }
          } else {
            ToastNotify({
              type: "error",
              message:
                res?.status >= 500
                  ? "Oops! Something went wrong"
                  : `${res?.data?.data?.responseMessage?.title}. ${res?.data?.data?.responseMessage?.actionMessage}` ||
                    "Something went wrong",
            });
          }
        })
        .catch((e: any) => {
          setisLoading(false);
          // setshowScreen(7);
        });

      // setshowScreen(7);
    } catch (e) {
      setisLoading(false);
    }
  };

  return {
    isLoading,
    authType,
    paymentState,
    showScreen,
    selectedBank,
    bankLists,
    accountNumber,
    accountValidated,
    accountValidationError,
    beneficiaryName,
    handleChange,
    handleBackToFundsTransfer,
    setisLoading,
    dispatch,
    setAccountValidated,
    setAccountValidationError,
    setBeneficiaryName,
    setAuthType,
    handleScreenChangeNext,
    accountToDebit,
    allAccounts,
    amount,
    description,
    setAccountToDebit,
    handleScreenChangePrev,
    showPinError,
    pinError,
    nipCharges,
    pin,
    setPin,
    setshowScreen,
    isPinValidated,
    authToken,
    setAuthToken,
    handleSubmit,
    allowSubmit,
    handleValidate,
    // responseCode,
    correlationId,
    otp,
    otpMessage,
    setOtp,
    setAmount,
    setSelectedBank,
    handleOtpSubmit,
    handleOtpValidationSubmit,
    handleRefreshTrasaction,
    handleGenerateReceipt,
    handleTryAgain,
  };
};

export default useNewTransfer;
